/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Proxima is a cutting-edge technology company that specializes in developing next-generation products\nand services. But their online presence was not reflecting that. The website was outdated, not\nuser-friendly and not in line with the company's brand. That's why they approached us to revamp\ntheir online presence and bring it in line with their cutting-edge products. The goal was to create\na website that was not only visually stunning, but also easy to navigate and fully-optimized for all\ndevices. In this blog post, we'll take a look at the design process, the challenges we faced, and\nthe final product. We'll also discuss the impact the new website has had on Proxima's business and\nbrand perception.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
